import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryFlightParams } from '../../../model/query-flight-params';
import * as _ from 'lodash';
import { Flight } from '../../../model/flight';
import { ContactData } from '../../../model/contact-data';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { HowItWorksDialogComponent } from '../../modal/how-it-works-dialog/how-it-works-dialog.component';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '../../../../shared/shared-data.service';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import 'rxjs/Rx';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeWhile';
import { formatDate, isEmptyDate, prepareDate } from '../../../../shared/util/date-util';
import { phoneMask, phonePattern } from '../../../app.constants';
import { getElementById, fitCountrySelect, getElementsByClass } from '../../../../shared/util/DOM-util';
import { NotifyService } from '../../../services/notify.service';
import { TrackingService } from '../../../services/tracking.service';
import { debounceTime, map, switchMap, takeWhile } from 'rxjs/operators';
import { interval } from 'rxjs';
import { SearchboxService } from '../searchbox/searchbox.service';
var PHONE_KEY = makeStateKey('phone');
var PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');
var SearchboxEuropeComponent = /** @class */ (function () {
    function SearchboxEuropeComponent(formBuilder, searchboxService, cookieService, route, platformId, router, dataService, state, appStorage, trackingService, notifyService, modalService) {
        this.formBuilder = formBuilder;
        this.searchboxService = searchboxService;
        this.cookieService = cookieService;
        this.route = route;
        this.platformId = platformId;
        this.router = router;
        this.dataService = dataService;
        this.state = state;
        this.appStorage = appStorage;
        this.trackingService = trackingService;
        this.notifyService = notifyService;
        this.modalService = modalService;
        this.activeForm = this.roundTripForm;
        this.queryFlightParams = new QueryFlightParams;
        this.flight = new Flight;
        this.flight1 = new Flight;
        this.flight2 = new Flight;
        this.locationData = [];
        this.formSending = false;
        this.contactData = new ContactData();
        this.phoneMask = phoneMask;
        this.showAddFlightButton = true;
        this.showThirdFlight = false;
        this.focusOut = false;
        this.mustSubmit = false;
        this.phoneVisible = false;
        this.lastFlight = new QueryFlightParams;
        this.clickTripType = true;
        this.flightSubmitted = false;
    }
    SearchboxEuropeComponent.prototype.ngOnInit = function () {
        this.initPhone();
        this.normalFlight = true;
        this.searchFormValid = true;
        this.queryPanelValid = true;
        this.initTripContent();
        this.queryFlightParams.tripType = 'Round';
        this.submitBtnText = 'Get Quote';
        this.initForms();
        this.initFields();
        this.initAutocompleteForAll();
    };
    Object.defineProperty(SearchboxEuropeComponent.prototype, "landingClass", {
        get: function () {
            return _.isNil(this.router) || _.isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    SearchboxEuropeComponent.prototype.changeMustSubmit = function (mustSubmit) {
        this.mustSubmit = mustSubmit;
    };
    SearchboxEuropeComponent.prototype.initFields = function () {
        var _this = this;
        this.searchboxService.getInitQueryFlightParams().subscribe(function (response) {
            _this.queryFlightParams = response;
            if (_.eq(response.cabin, 'BLANK')) {
                _this.queryFlightParams.cabin = 'BUSINESS';
            }
            _this.flight.from = response.flights[0].from;
            _this.flight.to = response.flights[0].to;
            _this.flight.departDate = prepareDate(response.flights[0].departDate);
            _this.returnDate = prepareDate(response.returnDate);
            _this.minDateReturn = prepareDate(response.flights[0].departDate, 1, 'd');
            if (response.flights.length > 1) {
                _this.flight1.from = response.flights[1].from;
                _this.flight1.to = response.flights[1].to;
                _this.flight1.departDate = prepareDate(response.flights[1].departDate);
                _this.minDateDepart1 = prepareDate(response.flights[0].departDate);
            }
            if (response.flights.length === 3) {
                _this.flight2.from = response.flights[2].from;
                _this.flight2.to = response.flights[2].to;
                _this.flight2.departDate = prepareDate(response.flights[2].departDate);
                _this.minDateDepart2 = prepareDate(response.flights[1].departDate);
                _this.showThirdFlight = true;
                _this.showAddFlightButton = false;
            }
            _this.setActiveForm(response.tripType);
            _this.initFlightDataNow = true;
            if (isPlatformBrowser(_this.platformId)) {
                _this.initDatepicker();
            }
        }, function (error) {
            if (isPlatformBrowser(_this.platformId)) {
                _this.initDatepicker();
            }
        });
    };
    SearchboxEuropeComponent.prototype.submitSearch = function () {
        if (this.activeForm === this.multiCityForm && !this.thirdFlightValid()) {
            return;
        }
        if (this.activeForm.valid && isPlatformBrowser(this.platformId)) {
            this.submit();
        }
    };
    SearchboxEuropeComponent.prototype.submit = function () {
        var _this = this;
        this.fillQueryFlightParams();
        if (!_.isUndefined(this.queryFlightParams.cabin) && !_.isUndefined(this.queryFlightParams.passengers)) {
            if (this.modelChanged()) {
                this.formSending = true;
                this.disableButton(true);
                this.trackingService.getTrack().subscribe(function () {
                    _this.searchboxService.submitSearch(_this.queryFlightParams).subscribe(function (response) {
                        _this.formSending = false;
                        _this.disableButton(false);
                        if (!_.isNil(response.data.error)) {
                            alert(response.data.error);
                        }
                        else {
                            // We lost dial code after contact form hid, to prevent it store code before (WSF-1766)
                            if (_this.normalFlight === true && response.data.normal === false) {
                                _this.contactData.cCode = _this.dialCode;
                            }
                            _this.normalFlight = response.data.normal;
                            // restore dial code (WSF-1766)
                            setTimeout(function () {
                                if (_this.phoneSelect) {
                                    _this.setCountry(_this.contactData.cCode);
                                }
                            });
                            _this.flightSubmitted = true;
                            _this.lastFlight = _.clone(_this.queryFlightParams);
                        }
                    }, function (error) {
                        _this.formSending = false;
                        _this.disableButton(false);
                    });
                }, function () {
                    _this.formSending = false;
                    _this.enableButton();
                    _this.showWarnNoty();
                });
            }
        }
    };
    SearchboxEuropeComponent.prototype.showWarnNoty = function () {
        this.notifyService.warn('Can\'t process your request now. Please try later.', {
            closeWith: ['click', 'button'],
            timeout: 10000
        });
    };
    SearchboxEuropeComponent.prototype.modelChanged = function () {
        return !_.isEqual(this.queryFlightParams, this.lastFlight);
    };
    SearchboxEuropeComponent.prototype.autoFillFromParam = function (name) {
        var _this = this;
        if (_.isNil(name) || _.isEmpty(name)) {
            return;
        }
        this.searchboxService.getLocations(name.replace('-', ' ')).subscribe(function (response) {
            if (response.length !== 0) {
                _this.flight.to = response[0];
            }
        }, function (error) {
            //    do nothing
        });
    };
    SearchboxEuropeComponent.prototype.initPhone = function () {
        var _this = this;
        if (!_.isNil(this.state.get(PHONE_KEY, null))) {
            this.phone = this.state.get(PHONE_KEY, null);
        }
        if (!_.isNil(this.state.get(PHONE_VISIBLE_KEY, null))) {
            this.phoneVisible = this.state.get(PHONE_VISIBLE_KEY, null);
        }
        this.subscription = this.dataService.getData()
            .subscribe(function (sharedData) {
            if (_.eq(sharedData.type, 'phone')) {
                _this.phone = sharedData.data;
            }
            if (_.eq(sharedData.type, 'phoneVisible')) {
                _this.phoneVisible = sharedData.data;
            }
            if (_.eq(sharedData.type, 'autoFillTo')) {
                _this.autoFillFromParam(sharedData.data);
            }
        });
    };
    SearchboxEuropeComponent.prototype.initTripContent = function () {
        this.queryFlightParams.cabin = 'BUSINESS';
        if (!_.isEmpty(this.appStorage.getItem('WF_CABIN_TYPE'))) {
            this.queryFlightParams.cabin = this.appStorage.getItem('WF_CABIN_TYPE');
        }
        this.queryFlightParams.passengers = 1;
        if (!_.isEmpty(this.appStorage.getItem('WF_ADULT'))) {
            this.queryFlightParams.passengers = Number(this.appStorage.getItem('WF_ADULT'));
        }
    };
    /* Start init autocomplete */
    SearchboxEuropeComponent.prototype.initAutocompleteForAll = function () {
        /* Init autocomplete for round trip */
        this.filteredFromData = this.initAutocomplete(this.roundTripForm, 'flyingFrom');
        this.filteredToData = this.initAutocomplete(this.roundTripForm, 'flyingTo');
        /* Init autocomplete for one way */
        this.filteredFromOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingFrom');
        this.filteredToOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingTo');
        /* Init autocomplete for multi city */
        this.filteredFromMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom');
        this.filteredToMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo');
        this.filteredFrom1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom1');
        this.filteredTo1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo1');
        this.filteredFrom2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom2');
        this.filteredTo2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo2');
    };
    SearchboxEuropeComponent.prototype.initAutocomplete = function (form, contrl) {
        var _this = this;
        return form.get(contrl).valueChanges
            .pipe(debounceTime(200), switchMap(function (value) {
            if (!_this.mustSubmit && _.isNil(value.match(/\D+\(\w+\)/i)) && !_.isEmpty(value)) {
                return _this.searchboxService.getLocations(value.trim())
                    .pipe(map(function (response) {
                    if (response.length === 0 && !_.isNull(value) && !_.isEmpty(value)) {
                        return Array.of('No cities found');
                    }
                    else {
                        return response;
                    }
                }));
            }
            else {
                return [];
            }
        }));
    };
    /* End init autocomplete */
    SearchboxEuropeComponent.prototype.initForms = function () {
        this.initRoundTripForm();
        this.initOneWayForm();
        this.initMultiCityForm();
        this.initQueryPanelForm();
        this.activeForm = this.roundTripForm;
    };
    SearchboxEuropeComponent.prototype.initRoundTripForm = function () {
        var _this = this;
        this.roundTripForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            departDate: ['', Validators.required],
            returnDate: ['', Validators.required]
        });
        this.roundTripForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function () {
            if (_this.roundTripForm.valid && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxEuropeComponent.prototype.initOneWayForm = function () {
        var _this = this;
        this.oneWayForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            departDate: ['', Validators.required]
        });
        this.oneWayForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function () {
            if (_this.oneWayForm.valid && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxEuropeComponent.prototype.initMultiCityForm = function () {
        var _this = this;
        this.multiCityForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingFrom1: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo1: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingFrom2: [''],
            flyingTo2: [''],
            departDate: ['', Validators.required],
            departDate1: ['', Validators.required],
            departDate2: ['']
        });
        this.multiCityForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function () {
            if (_this.multiCityForm.valid && _this.thirdFlightValid() && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxEuropeComponent.prototype.thirdFlightValid = function () {
        return this.showThirdFlight
            ? this.showThirdFlight
                && !_.isEmpty(this.multiCityForm.get('flyingFrom2').value)
                && !_.isEmpty(this.multiCityForm.get('flyingTo2').value)
                && !_.isEmpty(this.multiCityForm.get('departDate2').value)
            : true;
    };
    SearchboxEuropeComponent.prototype.initQueryPanelForm = function () {
        this.queryPanelForm = this.formBuilder.group({
            firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            phone: ['', Validators.compose([Validators.required, Validators.pattern(phonePattern)])]
        });
    };
    SearchboxEuropeComponent.prototype.initDatepicker = function () {
        this.firstDayOfWeek = 7;
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        this.displayMonths = width >= 768 ? 2 : 1;
        this.navigation = 'arrows';
        var now = new Date();
        this.maxDate = prepareDate(now, 3, 'y');
        this.minDateDepart = prepareDate(now);
        if (isEmptyDate(this.flight.departDate)) {
            this.minDateReturn = prepareDate(now, 1, 'd');
            this.minDateDepart1 = prepareDate(now, 1, 'd');
            this.minDateDepart2 = prepareDate(now, 1, 'd');
        }
    };
    SearchboxEuropeComponent.prototype.setActiveForm = function (tripType) {
        if (_.eq(tripType, 'Round')) {
            this.activeForm = this.roundTripForm;
        }
        else if (_.eq(tripType, 'OneWay')) {
            this.activeForm = this.oneWayForm;
        }
        else {
            this.activeForm = this.multiCityForm;
        }
    };
    SearchboxEuropeComponent.prototype.enableButton = function () {
        this.submitDisabled = false;
        this.submitBtnText = 'Send Me a Quote Now';
    };
    SearchboxEuropeComponent.prototype.fillQueryFlightParams = function () {
        this.setFlights();
    };
    SearchboxEuropeComponent.prototype.setFlights = function () {
        var flightCount = this.queryFlightParams.tripType === 'MultiCity' ?
            ((_.isUndefined(this.flight2.from) || _.isEmpty(this.flight2.from)) ? 2 : 3) : 1;
        var flights = new Array(flightCount);
        this.setFromTo(flights, flightCount);
        this.setDepart(flights, flightCount);
        if (this.queryFlightParams.tripType === 'Round') {
            this.setReturnDate();
        }
        this.queryFlightParams.flights = flights;
    };
    SearchboxEuropeComponent.prototype.setFromTo = function (flights, flightCount) {
        flights[0] = new Flight;
        flights[0].from = this.flight.from;
        flights[0].to = this.flight.to;
        if (flightCount >= 2) {
            flights[1] = new Flight;
            flights[1].from = this.flight1.from;
            flights[1].to = this.flight1.to;
        }
        if (flightCount === 3) {
            flights[2] = new Flight;
            flights[2].from = this.flight2.from;
            flights[2].to = this.flight2.to;
        }
    };
    SearchboxEuropeComponent.prototype.setDepart = function (flights, flightCount) {
        flights[0].departDate = formatDate(this.flight.departDate);
        if (flightCount >= 2) {
            flights[1].departDate = formatDate(this.flight1.departDate);
        }
        if (flightCount === 3) {
            flights[2].departDate = formatDate(this.flight2.departDate);
        }
    };
    SearchboxEuropeComponent.prototype.setReturnDate = function () {
        this.queryFlightParams.returnDate = formatDate(this.returnDate);
    };
    SearchboxEuropeComponent.prototype.disableButton = function (disable) {
        this.submitDisabled = disable;
        this.submitBtnText = disable ? 'Loading...' : 'Get Quote';
    };
    SearchboxEuropeComponent.prototype.setCountry = function (countryCode) {
        var currentCountry = _.filter(getElementsByClass('country'), function (country) {
            return _.eq(countryCode, country.getAttribute('data-dial-code'));
        });
        if (currentCountry.length > 0) {
            this.phoneSelect.setCountry(currentCountry[0].getAttribute('data-country-code'));
        }
    };
    SearchboxEuropeComponent.prototype.getCountryData = function () {
        return this.phoneSelect.getCountryData();
    };
    SearchboxEuropeComponent.prototype.changeCabinType = function (cabinType) {
        this.queryFlightParams.cabin = cabinType;
        if (this.activeForm.valid) {
            this.submitSearch();
        }
    };
    SearchboxEuropeComponent.prototype.changeTripType = function (tripType, currentForm) {
        this.queryFlightParams.tripType = tripType;
        this.activeForm = currentForm;
        this.focusOut = false;
        this.clickTripType = true;
    };
    SearchboxEuropeComponent.prototype.changeTravelers = function () {
        if (this.activeForm.valid) {
            this.submitSearch();
        }
    };
    /* Close Datepicker */
    SearchboxEuropeComponent.prototype.onClickedOutsideDp = function (event, dpId, inputId) {
        if (this.mustClose(event, inputId)) {
            if (_.includes(inputId, 'Depart1')) {
                this.activeDepart1 = false;
            }
            else if (_.includes(inputId, 'Depart2')) {
                this.activeDepart2 = false;
            }
            else if (_.includes(inputId, 'Depart')) {
                this.activeDepart = false;
            }
            else {
                this.activeReturn = false;
            }
            dpId.close();
        }
    };
    SearchboxEuropeComponent.prototype.mustClose = function (event, inputId) {
        var inDatepicker = false;
        _.each(event.target.classList, function (className) {
            if (_.includes(className, 'ngb') || _.eq(className, 'text-muted')) {
                inDatepicker = true;
            }
        });
        if (getElementById(inputId)) {
            return !(getElementById(inputId).contains(event.target) || inDatepicker);
        }
        else {
            return false;
        }
    };
    SearchboxEuropeComponent.prototype.getQuote = function () {
        var _this = this;
        if (!this.activeForm.valid) {
            this.searchFormValid = false;
            this.queryPanelValid = false;
            return;
        }
        if (!this.flightSubmitted) {
            this.submitSearch();
            this.showWarnNoty();
            return;
        }
        if (this.queryPanelForm.valid) {
            this.formSending = true;
            this.disableButton(true);
            this.preparePhone();
            this.searchFormValid = true;
            this.queryPanelValid = true;
            this.trackingService.getTrack().subscribe(function () {
                _this.searchboxService.sendRequest(_this.contactData).subscribe(function (response) {
                    _this.formSending = false;
                    _this.disableButton(false);
                    console.debug('Get quote: ' + response.data.details);
                    if (_.eq(response.status, 'FAILED')) {
                        alert(response.data.details);
                    }
                    else {
                        /* Lead
                         Track when a user expresses interest in your offering (ex. form submission, sign up for trial, landing on pricing page) */
                        // fbq('track', 'Lead');
                        _this.router.navigateByUrl('/your-request(s:a)');
                    }
                }, function (error) {
                    console.warn('Can\'t process your request now. Please try later.');
                    _this.disableButton(false);
                    _this.formSending = false;
                });
            }, function () {
                _this.formSending = false;
                _this.disableButton(false);
                _this.showWarnNoty();
            });
        }
        else {
            this.queryPanelValid = false;
        }
    };
    SearchboxEuropeComponent.prototype.preparePhone = function () {
        this.contactData.cCode = this.dialCode;
        this.contactData.phone = _.replace(this.contactPhoneModel, new RegExp('[ ()-]', 'g'), '');
    };
    Object.defineProperty(SearchboxEuropeComponent.prototype, "dialCode", {
        get: function () {
            var dialCode = this.phoneSelect.getCountryData().dialCode;
            return !_.isNil(dialCode) && dialCode !== '0' ? dialCode : '1';
        },
        enumerable: true,
        configurable: true
    });
    SearchboxEuropeComponent.prototype.onClickedOutsideTripContent = function (event) {
        if (_.eq(event.target.className, 'BusinessClassClick') ||
            _.eq(event.target.className, 'traveler') ||
            _.eq(event.target.className, 'fa fa-angle-down')) {
            return;
        }
        this.tripContent = false;
    };
    SearchboxEuropeComponent.prototype.onClickedOutsideCabinType = function (event) {
        if (_.eq(event.target.className, 'cabinClick') ||
            _.eq(event.target.className, 'cabinClick ng-star-inserted') ||
            _.eq(event.target.className, 'cabinClick fa fa-angle-down')) {
            return;
        }
        this.cabinContent = false;
    };
    SearchboxEuropeComponent.prototype.onClickedOutsideTripType = function (event) {
        if (_.eq(event.target.className, 'tripClick') ||
            _.eq(event.target.className, 'tripClick ng-star-inserted') ||
            _.eq(event.target.className, 'tripClick fa fa-angle-down')) {
            return;
        }
        this.tripTypeContent = false;
    };
    SearchboxEuropeComponent.prototype.onClickedOutsidePassengers = function (event) {
        if (_.eq(event.target.className, 'passengersClick') ||
            _.eq(event.target.className, 'passengersClick fa fa-angle-down')) {
            return;
        }
        this.passengersContent = false;
    };
    SearchboxEuropeComponent.prototype.initReturnDatepicker = function () {
        var _this = this;
        if (!isEmptyDate(this.flight.departDate)) {
            var date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
            this.minDateReturn = prepareDate(date, 1, 'd');
            if (!this.initFlightDataNow && this.changedDepartDateModel()) {
                setTimeout(function () { return _this.activeReturn = true; }, 0);
            }
        }
    };
    SearchboxEuropeComponent.prototype.changedDepartDateModel = function () {
        return _.isEmpty(this.lastFlight.flights) ? true :
            !_.eq(this.lastFlight.flights[0].departDate, formatDate(this.activeForm.get('departDate').value));
    };
    SearchboxEuropeComponent.prototype.initDepartDatepicker = function () {
        if (!isEmptyDate(this.flight.departDate)) {
            var date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
            this.minDateDepart1 = prepareDate(date);
        }
        if (!isEmptyDate(this.flight1.departDate)) {
            var date = new Date(this.flight1.departDate.year, this.flight1.departDate.month - 1, this.flight1.departDate.day);
            this.minDateDepart2 = prepareDate(date);
        }
    };
    SearchboxEuropeComponent.prototype.openHowItWorksDialog = function () {
        var _this = this;
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.modalService.open(HowItWorksDialogComponent, {
            windowClass: 'hiw-modal',
            backdropClass: 'hiw-backdrop'
        }).result.then(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        }, function (reason) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        });
        // dialogRef.close().subscribe(result => {
        //
        //   console.trace('The dialog was closed');
        // });
    };
    SearchboxEuropeComponent.prototype.handleClick = function () {
        if (isPlatformBrowser(this.platformId)) {
            fitCountrySelect();
            this.initFlightDataNow = false;
            this.clickTripType = false;
        }
    };
    SearchboxEuropeComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {
                _this.submitSearch();
                fitCountrySelect();
                /* Set contact info */
                _this.searchboxService.getInitContactData().subscribe(function (response) {
                    if (!_.isNull(response.data.contactInfo.email)) {
                        _this.contactData = response.data.contactInfo;
                        var splittedPhone = _.split(response.data.contactInfo.phone, ' ');
                        if (splittedPhone.length > 1) {
                            _this.contactPhoneModel = splittedPhone[1].replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                            _this.setCountry(_.replace(splittedPhone[0], '+', ''));
                        }
                    }
                });
            });
        }
    };
    SearchboxEuropeComponent.prototype.addFlight = function () {
        this.showAddFlightButton = false;
        this.showThirdFlight = true;
    };
    SearchboxEuropeComponent.prototype.ngOnDestroy = function () {
        // unsubscribe to ensure no memory leaks
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    SearchboxEuropeComponent.prototype.onFocusOutAirportInput = function (event) {
        if (_.isNil(event.relatedTarget) || (!_.isNil(event.relatedTarget) && !_.includes(event.relatedTarget.className, 'mat-option'))) {
            this.changeMustSubmit(true);
            if (this.formSending === false) {
                this.submitSearch();
            }
        }
    };
    SearchboxEuropeComponent.prototype.jumpToNextControl = function (element, autocompleteId) {
        var _this = this;
        if (_.eq(autocompleteId, 'flyingToRound') || _.eq(autocompleteId, 'flyingToOneWay') || _.eq(autocompleteId, 'flyingToMultiCity')) {
            setTimeout(function () { return _this.activeDepart = true; }, 0);
        }
        else if (_.eq(autocompleteId, 'flyingTo1MultiCity')) {
            setTimeout(function () { return _this.activeDepart1 = true; }, 0);
        }
        else if (_.eq(autocompleteId, 'flyingTo2MultiCity')) {
            setTimeout(function () { return _this.activeDepart2 = true; }, 0);
        }
        this.changeMustSubmit(true);
        if (element && !this.initFlightDataNow && !this.clickTripType) {
            if (element instanceof HTMLInputElement) {
                this.setTimeOut(element);
            }
            else {
                var nativeElement = element._elRef.nativeElement;
                if (element && (_.includes(nativeElement.id, 'Return') || _.includes(nativeElement.id, 'Depart'))) {
                    setTimeout(function () {
                        var currentAutocomplete = getElementById(autocompleteId);
                        if (currentAutocomplete) {
                            currentAutocomplete.blur();
                        }
                        element.toggle();
                    }, 100);
                }
            }
        }
    };
    SearchboxEuropeComponent.prototype.setTimeOut = function (element) {
        var _this = this;
        setTimeout(function () {
            if (_this.formSending) {
                interval(10)
                    .pipe(takeWhile(function () { return _this.formSending; }))
                    .subscribe(function () {
                    setTimeout(function () { return element.click(); }, 100);
                    // This will be called every 10 milliseconds until `formSending` flag is set to false
                });
            }
            else {
                setTimeout(function () { return element.click(); }, 100);
            }
        }, 100);
    };
    SearchboxEuropeComponent.prototype.capitalize = function (value) {
        return _.capitalize(value);
    };
    SearchboxEuropeComponent.prototype.onBlur = function (id) {
        var blurredElement = getElementById(id);
        setTimeout(function () { return blurredElement.blur(); }, 0);
    };
    SearchboxEuropeComponent.prototype.noWhitespaceValidator = function (control) {
        var isWhitespace = (control.value || '').trim().length === 0;
        var isValid = !isWhitespace;
        return isValid ? null : { 'isEmpty': true };
    };
    Object.defineProperty(SearchboxEuropeComponent.prototype, "withForm", {
        get: function () {
            return !this.router.url.includes('faq') &&
                !this.router.url.includes('about-us') &&
                !this.router.url.includes('terms-and-conditions') &&
                !this.router.url.includes('terms-and-conditions-app') &&
                !this.router.url.includes('insurance-terms') &&
                !this.router.url.includes('privacy-policy') &&
                !this.router.url.includes('corporate') &&
                !this.router.url.includes('reviews') &&
                !this.router.url.includes('our-team') &&
                !this.router.url.includes('your-request') &&
                !this.router.url.includes('privacy-app') &&
                !this.router.url.includes('contact-us') &&
                this.router.url.includes('europe') &&
                !this.router.url.includes('app-page');
        },
        enumerable: true,
        configurable: true
    });
    return SearchboxEuropeComponent;
}());
export { SearchboxEuropeComponent };
