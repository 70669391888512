import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ComponentBase } from '../../common/ComponentBase';
import { Router } from '@angular/router';
var HomeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HomeComponent, _super);
    function HomeComponent(router) {
        var _this = _super.call(this) || this;
        _this.router = router;
        return _this;
    }
    HomeComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(HomeComponent.prototype, "showDefaultForm", {
        get: function () {
            return (!this.router.url.includes('europe') || !this.router.url.includes('(s:'));
        },
        enumerable: true,
        configurable: true
    });
    return HomeComponent;
}(ComponentBase));
export { HomeComponent };
