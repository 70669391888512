import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
var NgbDateCustomParserFormatterFlights = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateCustomParserFormatterFlights, _super);
    function NgbDateCustomParserFormatterFlights(deviceService) {
        var _this = _super.call(this) || this;
        _this.deviceService = deviceService;
        _this.isMobile = _this.deviceService.isMobile();
        return _this;
    }
    NgbDateCustomParserFormatterFlights.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('/');
            if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
                return { year: this.toInteger(dateParts[0]), month: null, day: null };
            }
            else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
                return { year: this.toInteger(dateParts[1]), month: this.toInteger(dateParts[0]), day: null };
            }
            else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
                return {
                    year: this.toInteger(dateParts[2]),
                    month: this.toInteger(dateParts[1]),
                    day: this.toInteger(dateParts[0])
                };
            }
        }
        return null;
    };
    NgbDateCustomParserFormatterFlights.prototype.format = function (date) {
        var weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        var stringDate = '';
        if (date) {
            var fullDate = new Date(date.year, date.month - 1, date.day);
            if (!this.isMobile) {
                stringDate += weekDays[fullDate.getDay()] + ' ';
            }
            stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
            stringDate += this.isNumber(date.day) ? this.padNumber(date.day) : '';
        }
        return stringDate;
    };
    NgbDateCustomParserFormatterFlights.prototype.padNumber = function (value) {
        if (this.isNumber(value)) {
            return ("0" + value).slice(-2);
        }
        else {
            return '';
        }
    };
    NgbDateCustomParserFormatterFlights.prototype.isNumber = function (value) {
        return !isNaN(this.toInteger(value));
    };
    NgbDateCustomParserFormatterFlights.prototype.toInteger = function (value) {
        return parseInt("" + value, 10);
    };
    return NgbDateCustomParserFormatterFlights;
}(NgbDateParserFormatter));
export { NgbDateCustomParserFormatterFlights };
