import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { isPlatformBrowser } from '@angular/common';
import { ComponentBase } from '../ComponentBase';
import * as moment from 'moment';
var FooterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FooterComponent, _super);
    function FooterComponent(router, platformId) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.platformId = platformId;
        _this.fixBottom = false;
        return _this;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(filter(function (event) { return event instanceof NavigationStart; }))
                .subscribe(function (event) {
                _this.fixBottom = _.eq(event.url, '/your-request');
            });
        }
    };
    Object.defineProperty(FooterComponent.prototype, "isBusinessLand", {
        get: function () {
            var businessLanding = this.landingClass === 'First' ? false : true;
            if (!this.firstClassLandingDetected) {
                this.firstClassLandingDetected = !businessLanding;
            }
            return businessLanding && !this.firstClassLandingDetected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "landingClass", {
        get: function () {
            return _.isNil(this.router) || _.isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "year", {
        get: function () {
            return moment().format('YYYY'); // current year
        },
        enumerable: true,
        configurable: true
    });
    return FooterComponent;
}(ComponentBase));
export { FooterComponent };
