import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { CallMeDialogComponent } from '../../modal/call-me-dialog/call-me-dialog.component';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HeaderService } from './header.service';
import { NavigationStart, Router } from '@angular/router';
import { DataService } from '../../../../shared/shared-data.service';
import { interval } from 'rxjs';
import { cookieEnabled } from '../../../../shared/util/cookie-util';
import { TrackingService } from '../../../services/tracking.service';
import { ComponentBase } from '../ComponentBase';
import { filter, takeWhile } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var PHONE_KEY = makeStateKey('phone');
var PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');
var HeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HeaderComponent, _super);
    function HeaderComponent(dialog, appStorage, http, state, headerService, router, dataService, trackingService, modalService) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.appStorage = appStorage;
        _this.http = http;
        _this.state = state;
        _this.headerService = headerService;
        _this.router = router;
        _this.dataService = dataService;
        _this.trackingService = trackingService;
        _this.modalService = modalService;
        return _this;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cdnPath = environment.cdnPath;
        this.trackingService.getTrack().subscribe(function (data) {
            _this.setPhone();
            _this.checkTrackCookie();
        });
        this.showCallMeButton();
    };
    HeaderComponent.prototype.checkTrackCookie = function () {
        var _this = this;
        interval(500).pipe(takeWhile(function () {
            _this.trackId = _this.appStorage.getItem('WF_T');
            return !_this.trackId && cookieEnabled();
        }))
            .subscribe(function () { return console.debug('TrackId = ' + _this.trackId); }, function () { }, function () { return _this.dataService.sendData({ type: 'track', data: true }); });
    };
    HeaderComponent.prototype.setPhoneVisible = function () {
        var _this = this;
        this.headerService.isNight().subscribe(function (response) {
            _this.phoneVisible = !response.data.isNight;
            _this.sendPhoneVisibleData();
            _this.state.set(PHONE_VISIBLE_KEY, _this.phoneVisible);
        });
    };
    HeaderComponent.prototype.openCallMeDialog = function () {
        var _this = this;
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.modalService.open(CallMeDialogComponent, {
            windowClass: 'call-me-dialog',
            backdropClass: 'hiw-backdrop'
        });
        dialogRef.componentInstance.data = { phone: this.phone, phoneVisible: this.phoneVisible, isLaunchedFromHeader: true };
        dialogRef.result.then(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        }, function (reason) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        });
    };
    HeaderComponent.prototype.hideMenu = function () {
        this.navMenuCheckbox.nativeElement.checked = false;
        this.dataService.sendData({ type: 'showModal', data: false });
    };
    HeaderComponent.prototype.showMenu = function () {
        if (this.navMenuCheckbox.nativeElement.checked === false) {
            this.dataService.sendData({ type: 'showModal', data: true });
        }
        else {
            this.dataService.sendData({ type: 'showModal', data: false });
        }
    };
    HeaderComponent.prototype.setPhone = function () {
        var _this = this;
        var phoneFromStorage = this.appStorage.getItem('WF_PHONE');
        if (phoneFromStorage) {
            this.phone = phoneFromStorage;
            this.sendPhoneData();
            this.state.set(PHONE_KEY, this.phone);
            this.setPhoneVisible();
        }
        else {
            this.headerService.getPhone().subscribe(function (response) {
                if (!_.isNil(response.data.phone)) {
                    _this.phone = response.data.phone;
                    _this.sendPhoneData();
                    _this.state.set(PHONE_KEY, _this.phone);
                    _this.setPhoneVisible();
                }
            });
        }
    };
    HeaderComponent.prototype.sendPhoneData = function () {
        this.dataService.sendData({ type: 'phone', data: this.phone });
    };
    HeaderComponent.prototype.sendPhoneVisibleData = function () {
        this.dataService.sendData({ type: 'phoneVisible', data: this.phoneVisible });
    };
    HeaderComponent.prototype.showCallMeButton = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationStart; }))
            .subscribe(function (event) {
            _this.homePage = _.eq(event.url, '/home') || _.eq(event.url, '/');
        });
    };
    Object.defineProperty(HeaderComponent.prototype, "isBusinessLand", {
        get: function () {
            var businessLanding = this.landingClass === 'First' ? false : true;
            if (!this.firstClassLandingDetected) {
                this.firstClassLandingDetected = !businessLanding;
            }
            return businessLanding && !this.firstClassLandingDetected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "landingClass", {
        get: function () {
            return _.isNil(this.router) || _.isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    return HeaderComponent;
}(ComponentBase));
export { HeaderComponent };
