import { OnDestroy, OnInit } from '@angular/core';
import { isPlatformBrowser, LocationStrategy } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as _ from 'lodash';
import { DataService } from '../shared/shared-data.service';
import { NotifyService } from './services/notify.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { TagService } from './services/tag.service';
import { cookieEnabled } from '../shared/util/cookie-util';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BeforeCloseDialogComponent } from './layout/modal/before-close-dialog/before-close-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
var AppComponent = /** @class */ (function () {
    function AppComponent(platformId, router, locStrat, dataService, notifyService, http, tagService, modalService, cookieService, deviceService) {
        this.platformId = platformId;
        this.router = router;
        this.locStrat = locStrat;
        this.dataService = dataService;
        this.notifyService = notifyService;
        this.http = http;
        this.tagService = tagService;
        this.modalService = modalService;
        this.cookieService = cookieService;
        this.deviceService = deviceService;
        this.title = 'app';
        this.isPopState = false;
        this.scroll = function () {
            var elements = document.getElementsByClassName('navbar-expand');
            var header = elements[0];
            var offsetTop = document.documentElement.scrollTop;
            if (offsetTop > 82) {
                header.classList.add('fixed-header-change-bg');
            }
            else {
                header.classList.remove('fixed-header-change-bg');
            }
        };
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cdnPath = environment.cdnPath;
        if (isPlatformBrowser(this.platformId)) {
            window.document.addEventListener('scroll', this.scroll, true);
            this.locStrat.onPopState(function () {
                _this.isPopState = true;
            });
            this.router.events.subscribe(function (event) {
                // Scroll to top if accessing a page, not via browser history stack
                if (event instanceof NavigationEnd && !_this.isPopState) {
                    window.scrollTo(0, 0);
                    _this.isPopState = false;
                }
                // Ensures that isPopState is reset
                if (event instanceof NavigationEnd) {
                    _this.isPopState = false;
                }
            });
            this.router.events
                .filter(function (event) { return event instanceof NavigationStart; })
                .subscribe(function (event) {
                _this.showConversionCode = !_.eq(event.url, '/your-request');
            });
            this.subscription = this.dataService.getData()
                .subscribe(function (sharedData) {
                if (_.eq(sharedData.type, 'showSpinner')) {
                    _this.showSpinner = sharedData.data;
                }
                if (_.eq(sharedData.type, 'track')) {
                    _this.trackExist = sharedData.data;
                }
                if (_.eq(sharedData.type, 'showModal')) {
                    if (sharedData.data === true) {
                        _this.hideScroll();
                    }
                    else {
                        _this.showScroll();
                    }
                }
            });
            this.checkCookie();
            interval(500).pipe(takeWhile(function () {
                if (ga) {
                    ga.getAll().forEach(function (tracker) {
                        _this.clientId = tracker.get('clientId');
                    });
                }
                return !_this.clientId && cookieEnabled();
            }))
                .subscribe(function () { return console.debug('ClientId = ' + _this.clientId); }, function () { }, function () { return _this.saveClientId(); });
            interval(500).pipe(takeWhile(function () {
                return !_this.trackExist && cookieEnabled();
            }))
                .subscribe(function () { return console.debug('trackExist = ' + _this.trackExist); }, function () { }, function () { return _this.saveClientId(); });
        }
        // setTimeout(() => { this.showExitModal(); }, 5000);
    };
    AppComponent.prototype.saveClientId = function () {
        if (!!this.clientId && this.trackExist) {
            this.http.get(environment.serverUrl + '/save/client-id', { params: new HttpParams().set('clientId', this.clientId) }).subscribe(function () {
                console.debug('Save clientId complete');
            });
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.document.removeEventListener('scroll', this.scroll, true);
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        }
    };
    AppComponent.prototype.checkCookie = function () {
        this.http.get(environment.serverUrl + '/check-cookie', { params: new HttpParams().set('cookieEnabled', cookieEnabled() ? 'true' : 'false') }).subscribe(function () {
            console.debug('Check cookie complete');
        });
    };
    AppComponent.prototype.isEmpty = function (fieldValue) {
        return _.isEmpty(fieldValue) || _.isNil(fieldValue);
    };
    AppComponent.prototype.onMouseLeave = function ($event) {
        if ($event.clientY > 15) {
            return;
        }
        // this.showExitModal();
    };
    AppComponent.prototype.showExitModal = function () {
        var _this = this;
        if (!this.isEmpty(this.cookieService.get('WF_GURU_INSIDER_SENT')) ||
            this.modalService.hasOpenModals() ||
            this.router.url.includes('corporate-private') ||
            this.deviceService.isMobile()) {
            return;
        }
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.modalService.open(BeforeCloseDialogComponent, {
            windowClass: 'before-close-dialog',
            backdropClass: 'hiw-backdrop'
        });
        dialogRef.result.then(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        }, function (reason) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        });
    };
    AppComponent.prototype.hideScroll = function () {
        var scrollSize = window.innerWidth - document.body.scrollWidth;
        var blocks = document.getElementsByClassName('main-block');
        _.forEach(blocks, function (elem) {
            elem.style.width = 'calc(100% - ' + scrollSize + 'px)';
        });
        // document.body.className += ' ' + 'modal-open';
    };
    AppComponent.prototype.showScroll = function () {
        // document.body.classList.remove('modal-open');
        document.body.style.paddingRight = '0px';
        var blocks = document.getElementsByClassName('main-block');
        _.forEach(blocks, function (elem) {
            elem.style.width = '100%';
        });
    };
    Object.defineProperty(AppComponent.prototype, "withOutletForm", {
        get: function () {
            return !this.router.url.includes('faq') &&
                !this.router.url.includes('about-us') &&
                !this.router.url.includes('terms-and-conditions') &&
                !this.router.url.includes('terms-and-conditions-app') &&
                !this.router.url.includes('insurance-terms') &&
                !this.router.url.includes('privacy-policy') &&
                !this.router.url.includes('corporate') &&
                !this.router.url.includes('reviews') &&
                !this.router.url.includes('our-team') &&
                !this.router.url.includes('your-request') &&
                !this.router.url.includes('privacy-app') &&
                !this.router.url.includes('contact-us') &&
                !this.router.url.includes('app-page') &&
                this.router.url.includes('europe') &&
                !this.router.url.includes('all-business-class');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isSpecialCityOutlet", {
        get: function () {
            return this.router.url.toLowerCase().includes('business-class-flights-to-rome') ||
                this.router.url.toLowerCase().includes('business-class-flights-to-venice') ||
                this.router.url.toLowerCase().includes('business-class-flights-to-athens') ||
                this.router.url.toLowerCase().includes('business-class-flights-to-europe') ||
                this.router.url.toLowerCase().includes('business-class-flights-to-italy');
        },
        enumerable: true,
        configurable: true
    });
    return AppComponent;
}());
export { AppComponent };
