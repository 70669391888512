import * as tslib_1 from "tslib";
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import * as _ from 'lodash';
import { CustomHttpParameterCodec } from './custom-http-parameter-codec';
import { DataService } from '../../shared/shared-data.service';
import { NotifyService } from '../services/notify.service';
import { ApplicationEventBroadcaster } from '../../shared/application.event.broadcaster';
import { TrackingFinishRequestEvent, TrackingStartRequestEvent } from '../services/tracking.service';
import { tap } from 'rxjs/operators';
var MainInterceptor = /** @class */ (function () {
    function MainInterceptor(platformId, dataService, notifyService, broadcaster) {
        var _this = this;
        this.platformId = platformId;
        this.dataService = dataService;
        this.notifyService = notifyService;
        this.broadcaster = broadcaster;
        this.SHOW_TIMEOUT = 200;
        this.HIDE_TIMEOUT = 300;
        this.countRequest = 0;
        this.loaderVisible = false;
        this.showTimerId = null;
        this.hideTimerId = null;
        this.dataService.getData()
            .subscribe(function (sharedData) {
            if (_.eq(sharedData.type, 'updatesApplied')) {
                _this.isUpdatesApplied = sharedData.data;
            }
            if (_.eq(sharedData.type, 'notyShowing')) {
                _this.isNotyShowing = sharedData.data;
            }
        });
    }
    MainInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        if (this.needShowLoading(req.url)) {
            this.onStartRequest();
        }
        // Clone the request to add the new headers
        var clonedRequest;
        if (isPlatformBrowser(this.platformId)) {
            if (_.includes(req.url, '/track')) {
                this.startTracking(true);
            }
            var params = location.search
                .slice(1)
                .split('&')
                .map(function (p) { return p.split('='); })
                .reduce(function (obj, pair) {
                var _a;
                var _b = pair.map(decodeURIComponent), key = _b[0], value = _b[1];
                /* Workaround that adblock not block requests with 'adgroupid' parameter with enabled SW */
                if (key == 'adgroupid') {
                    key = 'adgroup';
                }
                return (tslib_1.__assign({}, obj, (_a = {}, _a[key] = value, _a)));
            }, {});
            var originParams = (_.includes(req.url, '/track') || _.includes(req.url, '/is-night') || _.includes(req.url, '/get-search-params') || _.includes(req.url, '/get-booking-params'))
                && _.values(params).some(function (param) { return !_.isEmpty(param); }) ? new HttpParams({
                fromObject: params,
                encoder: new CustomHttpParameterCodec()
            }) : req.params;
            var headers = req.headers.append('X-Original-URL', location.href).append('X-Site', 'www.businessclassguru.com');
            if (!_.isEmpty(document.referrer)) {
                headers = headers.append('X-Original-Referer', document.referrer);
            }
            clonedRequest = req.clone({
                withCredentials: true,
                params: originParams,
                headers: headers
            });
        }
        else {
            clonedRequest = req.clone({
                withCredentials: true
            });
        }
        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                if (_.includes(event.url, '/track')) {
                    _this.startTracking(false);
                }
                if (_this.needShowLoading(req.url)) {
                    _this.onStopRequest();
                }
            }
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                if (_.includes(err.url, '/track')) {
                    _this.startTracking(false);
                }
                if (_this.needShowLoading(req.url)) {
                    _this.onStopRequest();
                }
                if (_this.needShowNoty(req.url)) {
                    if (err.status == 500 || err.status == 400) {
                        /*if (this.isUpdatesApplied == false) {
                          if (!this.isNotyShowing) {
                            this.notifyService.warnWithAvailableUpdates({
                              text: 'Can\'t process your request now. Our site was updated. We\'re strongly recommended to reload page. Otherwise we cannot guarantee stable work.'
                            });
                          }
                        } else {*/
                        if (!_this.isNotyShowing) {
                            _this.notifyService.error('Can\'t process your request now. Please try later.', {
                                closeWith: ['click', 'button'],
                                timeout: 10000
                            });
                        }
                        /*}*/
                    }
                    else if (err.status === 502 || err.status === 503 || err.status === 504 || err.status === 599) {
                        if (!_this.isNotyShowing) {
                            _this.notifyService.warn('Can\'t process your request now. Please try later.', {
                                closeWith: ['click', 'button'],
                                timeout: 10000
                            });
                        }
                    }
                    else {
                        if (!_this.isNotyShowing) {
                            _this.notifyService.error('Can\'t process your request now. Please try later.', {
                                closeWith: ['click', 'button'],
                                timeout: 10000
                            });
                        }
                    }
                }
            }
        }));
    };
    MainInterceptor.prototype.needShowNoty = function (url) {
        return _.includes(url, '/flight/query-flight')
            || _.includes(url, '/contact-info/get-quote')
            || _.includes(url, '/contact-info/corporate')
            || _.includes(url, '/contact-info/personal')
            || _.includes(url, '/contact-info/callback')
            || _.includes(url, '/contact-info/contact-us')
            || _.includes(url, '/reviews/save-review');
    };
    MainInterceptor.prototype.needShowLoading = function (url) {
        return _.includes(url, '/users') || _.includes(url, '/reviews/get-reviews-by-page') || _.includes(url, '/unsubscribe');
    };
    MainInterceptor.prototype.onStartRequest = function () {
        var _this = this;
        this.countRequest++;
        if (this.hideTimerId) {
            clearTimeout(this.hideTimerId);
            this.hideTimerId = null;
        }
        if (!this.loaderVisible && !this.showTimerId) {
            this.showTimerId = setTimeout(function () { return _this.showLoader(true); }, this.SHOW_TIMEOUT);
        }
    };
    MainInterceptor.prototype.onStopRequest = function () {
        var _this = this;
        this.countRequest--;
        if (this.countRequest <= 0) {
            this.countRequest = 0;
            if (this.showTimerId) {
                clearTimeout(this.showTimerId);
                this.showTimerId = null;
            }
            if (!this.hideTimerId) {
                this.hideTimerId = setTimeout(function () { return _this.showLoader(false); }, this.HIDE_TIMEOUT);
            }
        }
    };
    MainInterceptor.prototype.showLoader = function (showLoader) {
        this.loaderVisible = showLoader;
        this.dataService.sendData({ type: 'showSpinner', data: showLoader });
    };
    MainInterceptor.prototype.startTracking = function (start) {
        if (start) {
            this.broadcaster.next(new TrackingStartRequestEvent());
        }
        else {
            this.broadcaster.next(new TrackingFinishRequestEvent());
        }
    };
    return MainInterceptor;
}());
export { MainInterceptor };
