import * as tslib_1 from "tslib";
import { DefaultUrlSerializer } from '@angular/router';
var OrderUrlSerializer = /** @class */ (function (_super) {
    tslib_1.__extends(OrderUrlSerializer, _super);
    function OrderUrlSerializer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrderUrlSerializer.prototype.parse = function (url) {
        if (url.indexOf('business-class-flights-to-') >= 0) {
            url = url.replace('business-class-flights-to-', 'business-class-flights-to/');
        }
        else if (url.indexOf('business-class-airlines-') >= 0) {
            url = url.replace('business-class-airlines-', 'business-class-airlines/');
        }
        else if (url.indexOf('business-class-') >= 0) {
            url = url.replace('business-class-', 'business-class/');
        }
        else if (url.indexOf('first-class-flights-to-') >= 0) {
            url = url.replace('first-class-flights-to-', 'first-class-flights-to/');
        }
        else if (url.indexOf('first-class') >= 0) {
            url = url.replace('first-class-', 'first-class/');
        }
        else if (url.indexOf('we-talking-about') >= 0) {
            url = url.replace('we-talking-about-', 'we-talking-about/');
        }
        return _super.prototype.parse.call(this, url);
    };
    OrderUrlSerializer.prototype.serialize = function (tree) {
        var url = _super.prototype.serialize.call(this, tree);
        if (url.indexOf('business-class-flights-to/') >= 0) {
            url = url.replace('business-class-flights-to/', 'business-class-flights-to-');
        }
        else if (url.indexOf('business-class-airlines/') >= 0) {
            url = url.replace('business-class-airlines/', 'business-class-airlines-');
        }
        else if (url.indexOf('business-class/') >= 0) {
            url = url.replace('business-class/', 'business-class-');
        }
        else if (url.indexOf('first-class-flights-to/') >= 0) {
            url = url.replace('first-class-flights-to/', 'first-class-flights-to-');
        }
        else if (url.indexOf('first-class/') >= 0) {
            url = url.replace('first-class/', 'first-class-');
        }
        else if (url.indexOf('we-talking-about/') >= 0) {
            url = url.replace('we-talking-about/', 'we-talking-about-');
        }
        return url;
    };
    return OrderUrlSerializer;
}(DefaultUrlSerializer));
export { OrderUrlSerializer };
