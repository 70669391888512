import * as tslib_1 from "tslib";
var SearchboxFlightsMobileService = /** @class */ (function () {
    function SearchboxFlightsMobileService() {
        this.flightQuoteData = {
            queryCruiseParams: {
                tripType: null,
                cabin: null,
                passengers: null
            }
        };
    }
    SearchboxFlightsMobileService.prototype.setFlightOptions = function (flightOptions) {
        this.flightQuoteData.queryCruiseParams = tslib_1.__assign({}, flightOptions);
    };
    SearchboxFlightsMobileService.prototype.getFlightQuoteData = function () {
        return this.flightQuoteData;
    };
    return SearchboxFlightsMobileService;
}());
export { SearchboxFlightsMobileService };
