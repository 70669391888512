import * as tslib_1 from "tslib";
import { Type } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import * as i0 from "@angular/core";
var ApplicationEventBroadcaster = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationEventBroadcaster, _super);
    function ApplicationEventBroadcaster() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApplicationEventBroadcaster.prototype.onType = function (messageClass) {
        return this.asObservable().filter(function (event) { return event instanceof messageClass; });
    };
    ApplicationEventBroadcaster.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationEventBroadcaster_Factory() { return new ApplicationEventBroadcaster(); }, token: ApplicationEventBroadcaster, providedIn: "root" });
    return ApplicationEventBroadcaster;
}(Subject));
export { ApplicationEventBroadcaster };
